.primaryColor {
  background: #2e383d;
  color: white;
}

.iconsDiv {
  width: 200px;
}

.mainDiv {
  width: 600px !important;
  border-radius: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
}
