.primaryColor {
  background: #2e383d;
  color: white;
  width: 380px;
  border: none !important;
}

.btnsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.mainDiv {
  border-radius: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
}
