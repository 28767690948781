.primaryColor {
  background: #101010;
  color: white;
}

.mainDiv {
  width: 650px !important;
  border-radius: 15px !important;
  height: 340px !important;
  position: absolute;
  top: 20px;
  right: 20px;
}

.topDiv {
  border-radius: 15px !important;
}

.middleDiv {
  display: flex;
  background: #c3c3c3;
  border-radius: 50px;
  padding: 1rem;
  margin: auto auto;
  align-items: start;
  color: black;
}

.backgroundColor {
  background: #101010 !important;
}

.bottomDiv {
  background: #575e61;
  color: white;
  border-radius: 10px;
  margin-right: 16%;
  margin-left: 16%;
}

.microphoneMutedText {
  font-size: 20px;
}

.cancel {
  border-radius: 20px !important;
  background: #c3c3c3 !important;
  border-color: #c3c3c3 !important;
}
