.cancel {
  border-radius: 20px !important;
  background: #c3c3c3 !important;
  border-color: #c3c3c3 !important;
}

.primaryColor {
  background: white;
  /* color: white; */
}

.backgroundColor {
  background: #101010 !important;
}

.mainDiv {
  width: 400px !important;
  border-radius: 15px;
  height: 700px;
  border: 2px solid;
  position: absolute;
  top: 20px;
  right: 20px;
}

.bottomDiv {
  background: #575e61;
  color: white;
  border-radius: 10px;
  margin-right: 16%;
  margin-left: 16%;
}

.microphoneMutedText {
  font-size: 20px;
}

.participant {
  width: 175px;
  height: 100px;
  border-radius: 5px;
}

.mutedOuterDiv {
  margin-top: 400px;
}

.mutedDiv {
  display: flex;
  background: #c3c3c3;
  border-radius: 50px;
  padding: 0.5rem;
  margin: auto auto;
  align-items: start;
  color: black;
  z-index: 1;
}

.mutedMic {
  position: absolute;
}

.endCall {
  z-index: 1;
}
