.flag-dropdown {
  border: none !important;
  background: none !important;
}

.phoneInput > input[type=tel] {
  background: #F6F6F6 !important;
  border-radius: 50px !important;
  height: 50px !important;
  width: 100% !important;
  border: none !important;
}

.flag{
  margin-left: 10px !important;
}

a {
  text-decoration: none !important;
}

.owl-dot{
  display: none !important;
}

.single-screenshot-card > img {
  border-radius: 10% !important;
}

* {
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*================================================
Gradient Main Banner Area CSS
=================================================*/
.gradient-main-banner .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.gradient-main-banner-inner {
  z-index: 1;
  position: relative;
  background-image: url("../public/assets/img/img/bg.png");
  padding-top: 100px;
  padding-left: 60px;
  padding-right: 60px; background-size: 100%;;
}

.gradient-main-banner-inner .content {
  padding-right: 15px;
  position: relative;
  top: 100px;
}

.gradient-main-banner-inner .content .sub-title {
  background-color: rgba(255, 255, 255, 0.15);
  color: var(--whiteColor);
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 20px;
  padding: 7px 20px;
}

.gradient-main-banner-inner .content h1 {
  color: var(--whiteColor);
  margin-bottom: 20px;
  line-height: 1.4;
  font-size: 48px;
}

.gradient-main-banner-inner .content p {
  color: var(--whiteColor);
  max-width: 560px;
  font-size: 16px;
  opacity: .9;
}

.gradient-main-banner-inner .content .default-btn {
  margin-top: 10px;
  color: var(--whiteColor);
  background-color: var(--blackColor);
}

.gradient-main-banner-inner .content .default-btn::before {
  opacity: 0;
  visibility: hidden;
}

.gradient-main-banner-inner .content .default-btn:hover {
  color: var(--blackColor);
  background-color: var(--whiteColor);
}

.gradient-main-banner-inner .image {
  text-align: center;
}

.gradient-main-banner-inner .banner-shape3 {
  bottom: 30%;
  left: auto;
  right: .5%;
}

.banner-shape9 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 45%;
}

.banner-shape9 img {
  -webkit-animation: moveleftbounce 5s linear infinite;
          animation: moveleftbounce 5s linear infinite;
}

.banner-shape10 {
  position: absolute;
  bottom: 10px;
  z-index: -1;
  right: 1%;
}

.banner-shape10 img {
  -webkit-animation: opacitychange 4s linear 1s infinite;
          animation: opacitychange 4s linear 1s infinite;
}

.banner-shape11 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-image {
  padding-right: 15px;
  text-align: center;
}

.testimonials-content {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 30px;
  margin-left: 15px;
  margin-bottom: 30px;
}

.testimonials-content .sub-title {
  display: block;
  margin-bottom: 10px;
  background: var(--gradientColor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.1em;
  font-weight: 600;
}

.testimonials-content h2 {
  line-height: 1.4;
  margin-bottom: 0;
  font-size: 36px;
}

.testimonials-slides {
  margin-left: 15px;
  max-width: 680px;
}

.testimonials-slides .content h3 {
  font-size: 20px;
  margin-bottom: 9px;
}

.testimonials-slides .content span {
  display: block;
  margin-bottom: 20px;
  color: var(--mainColor);
}

.testimonials-slides .content p {
  z-index: 1;
  font-size: 20px;
  position: relative;
}

.testimonials-slides .content p::before {
  opacity: .09;
  content: "\EC51";
  font-family: remixicon;
  position: absolute;
  font-size: 100px;
  line-height: 1;
  z-index: -1;
  top: -20px;
  left: 0;
}

.testimonials-slides .content p::after {
  opacity: .09;
  content: "\EC52";
  font-family: remixicon;
  position: absolute;
  font-size: 100px;
  line-height: 1;
  bottom: -20px;
  z-index: -1;
  right: 0;
}

.testimonials-slides.owl-theme .owl-nav {
  margin-top: 20px;
  text-align: start;
}

.testimonials-slides.owl-theme .owl-nav [class*=owl-] {
  position: relative;
  font-size: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f1f1f1;
  color: var(--mainColor);
  margin: 0;
  line-height: 42px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
}

.testimonials-slides.owl-theme .owl-nav [class*=owl-]::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: var(--gradientColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

.testimonials-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  margin-left: 10px;
}

.testimonials-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #f9f9f9;
  color: var(--whiteColor);
  border-radius: 50%;
}

.testimonials-slides.owl-theme .owl-nav [class*=owl-]:hover::before {
  opacity: 1;
  border-radius: 50%;
  visibility: visible;
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-box {
  padding: 30px;
  text-align: center;
  margin-bottom: 25px;
  background: var(--gradientColor);
}

.funfacts-box .icon {
  line-height: 1;
  font-size: 55px;
  margin-bottom: 15px;
  color: var(--whiteColor);
}

.funfacts-box p {
  opacity: .95;
  line-height: 1;
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--whiteColor);
}

.funfacts-box h3 {
  line-height: 1;
  font-size: 40px;
  margin-bottom: 0;
  color: var(--whiteColor);
}

.funfacts-box h3 .sign {
  position: relative;
  top: 2px;
}

.funfacts-box.bg1 {
  background: linear-gradient(45.24deg, #9F5FF1 5.99%, #FF54B0 91.87%);
}

.funfacts-box.bg2 {
  background: linear-gradient(44.44deg, #3F75FF 7.79%, #4ADEFF 94.18%);
}

.funfacts-box.bg3 {
  background: linear-gradient(44.44deg, #FF4B3F 7.79%, #FFAC30 94.18%);
}

/*================================================
Features Area CSS
=================================================*/
.features-inner.gradient-color {
  background: linear-gradient(50.48deg, #ED2775 -2.06%, #FF7448 93.55%);
}

.features-inner.gradient-color .features-box .icon {
  padding: 0;
  width: auto;
  height: auto;
  line-height: 1;
  margin-bottom: 15px;
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  background: transparent !important;
  color: var(--whiteColor) !important;
}

.features-inner.gradient-color .features-box .icon::before {
  display: none;
}

.features-inner.gradient-color .features-box .icon::after {
  display: none;
}

.features-inner.gradient-color .features-box .icon i {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  position: relative;
  top: 0;
}

.features-inner.gradient-color .features-box h3 {
  color: var(--whiteColor);
}

.list-of-features {
  padding-left: 15px;
}

.list-of-features .row .col-lg-6:nth-child(2) {
  margin-top: 25px;
}

.list-of-features .row .col-lg-6:nth-child(3) {
  margin-top: -25px;
}

.list-of-features .features-item {
  padding: 20px;
}

/*================================================
Screenshots Area CSS
=================================================*/
.screenshots-tabs {
  text-align: center;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

.screenshots-tabs .nav-tabs {
  display: block;
  -ms-flex-wrap: unset;
      flex-wrap: unset;
  border-bottom: 1px solid #444444;
}

.screenshots-tabs .nav-tabs .nav-item {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.screenshots-tabs .nav-tabs .nav-item .nav-link {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 25px;
  padding-bottom: 12px;
}

.screenshots-tabs .nav-tabs .nav-item .nav-link i {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  margin-top: -1px;
  left: 25px;
  top: 50%;
}

.screenshots-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--gradientColor);
  border-color: transparent;
  color: var(--whiteColor);
}

.screenshots-tabs .nav-tabs .nav-item .nav-link:hover {
  border-color: #444444;
}

.screenshots-tabs .tab-content {
  margin-top: 30px;
}

.screenshots-tabs .tab-content img {
  -webkit-filter: drop-shadow(25px 50px 70px rgba(42, 50, 61, 0.15));
          filter: drop-shadow(25px 50px 70px rgba(42, 50, 61, 0.15));
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .gradient-main-banner .container-fluid {
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
  .gradient-main-banner-inner {
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gradient-main-banner-inner .content {
    text-align: center;
    padding-right: 0;
    top: 0;
  }
  .gradient-main-banner-inner .content .sub-title {
    margin-bottom: 15px;
    padding: 5px 15px;
    font-size: 12px;
  }
  .gradient-main-banner-inner .content h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .gradient-main-banner-inner .content .default-btn {
    margin-top: 5px;
  }
  .gradient-main-banner-inner .content p {
    max-width: 100%;
    font-size: 13px;
  }
  .gradient-main-banner-inner .image {
    margin-top: 30px;
  }
  .banner-shape9 {
    display: none;
  }
  .list-of-features {
    padding-left: 0;
    margin-top: 30px;
  }
  .list-of-features .row .col-lg-6:nth-child(2) {
    margin-top: 0;
  }
  .list-of-features .row .col-lg-6:nth-child(3) {
    margin-top: 0;
  }
  .testimonials-image {
    padding-right: 0;
    margin-bottom: 30px;
  }
  .testimonials-content {
    padding-bottom: 25px;
    text-align: center;
    margin-left: 0;
    margin-bottom: 25px;
  }
  .testimonials-content .sub-title {
    font-size: 12px;
  }
  .testimonials-content h2 {
    font-size: 20px;
  }
  .testimonials-slides {
    margin-left: 0;
    max-width: 100%;
    text-align: center;
  }
  .testimonials-slides .content h3 {
    font-size: 16px;
  }
  .testimonials-slides .content p {
    font-size: 14px;
  }
  .testimonials-slides.owl-theme .owl-nav {
    text-align: center;
  }
  .testimonials-slides.owl-theme .owl-nav [class*=owl-] {
    width: 35px;
    height: 35px;
    font-size: 20px;
    line-height: 35px;
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .screenshots-tabs {
    max-width: 100%;
  }
  .screenshots-tabs .nav-tabs {
    padding-bottom: 15px;
  }
  .screenshots-tabs .nav-tabs .nav-item {
    margin-bottom: 15px;
  }
  .screenshots-tabs .nav-tabs .nav-item .nav-link {
    font-size: 13px;
    margin-bottom: 0;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
    padding-top: 8px;
    padding-left: 35px;
    padding-right: 15px;
    padding-bottom: 8px;
  }
  .screenshots-tabs .nav-tabs .nav-item .nav-link i {
    left: 15px;
  }
  .funfacts-box {
    padding: 20px 15px;
  }
  .funfacts-box .icon {
    font-size: 40px;
  }
  .funfacts-box p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .funfacts-box h3 {
    font-size: 30px;
  }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .gradient-main-banner-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gradient-main-banner .container-fluid {
    max-width: 720px;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
  .gradient-main-banner-inner {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 80px;
  }
  .gradient-main-banner-inner .content {
    text-align: center;
    padding-right: 0;
    top: 0;
  }
  .gradient-main-banner-inner .content h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .gradient-main-banner-inner .content .sub-title {
    font-size: 13px;
    padding: 6px 20px;
  }
  .gradient-main-banner-inner .content p {
    font-size: 14px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .gradient-main-banner-inner .content .default-btn {
    margin-top: 8px;
  }
  .gradient-main-banner-inner .image {
    margin-top: 30px;
  }
  .list-of-features {
    padding-left: 0;
    margin-top: 30px;
  }
  .testimonials-image {
    padding-right: 0;
    margin-bottom: 30px;
  }
  .testimonials-content {
    padding-left: 0;
    text-align: center;
  }
  .testimonials-content .sub-title {
    font-size: 13px;
  }
  .testimonials-content h2 {
    font-size: 30px;
  }
  .testimonials-slides {
    margin-left: 0;
    max-width: 100%;
  }
  .testimonials-slides .content h3 {
    font-size: 18px;
  }
  .testimonials-slides .content p {
    font-size: 16px;
  }
  .testimonials-slides.owl-theme .owl-nav {
    text-align: center;
  }
  .screenshots-tabs {
    max-width: 100%;
  }
  .screenshots-tabs .nav-tabs .nav-item {
    margin-left: 3px;
    margin-right: 3px;
  }
  .screenshots-tabs .nav-tabs .nav-item .nav-link {
    font-size: 14px;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 20px;
    padding-bottom: 10px;
  }
  .screenshots-tabs .nav-tabs .nav-item .nav-link i {
    margin-top: 0;
    left: 20px;
  }
  .funfacts-box .icon {
    font-size: 45px;
  }
  .funfacts-box p {
    font-size: 15px;
  }
  .funfacts-box h3 {
    font-size: 30px;
  }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gradient-main-banner .container-fluid {
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
  .gradient-main-banner-inner {
    padding-left: 30px;
    padding-right: 30px;
  }
  .gradient-main-banner-inner .content {
    padding-right: 0;
    top: 0;
  }
  .gradient-main-banner-inner .content h1 {
    font-size: 38px;
  }
  .gradient-main-banner-inner .content p {
    max-width: 100%;
    font-size: 14px;
  }
  .banner-shape10 {
    right: 0;
  }
  .list-of-features {
    padding-left: 0;
  }
  .list-of-features .features-item h3 {
    font-size: 18px;
  }
  .list-of-features .features-item.with-border {
    padding: 20px 15px;
  }
  .testimonials-image {
    padding-right: 0;
  }
  .testimonials-content {
    padding-bottom: 20px;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .testimonials-slides {
    margin-left: 0;
    max-width: 100%;
  }
  .testimonials-slides .content p {
    font-size: 17px;
  }
  .screenshots-tabs {
    max-width: 100%;
  }
  .funfacts-box {
    padding: 20px;
  }
  .funfacts-box p {
    font-size: 16px;
  }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .gradient-main-banner .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .gradient-main-banner-inner {
    padding-left: 40px;
    padding-right: 40px;
  }
  .gradient-main-banner-inner .content {
    padding-right: 0;
    top: 30px;
  }
  .gradient-main-banner-inner .content p {
    max-width: 100%;
    font-size: 15px;
  }
  .gradient-main-banner-inner .content h1 {
    font-size: 44px;
  }
  .list-of-features {
    padding-left: 0;
  }
  .list-of-features .features-item.with-border {
    padding: 25px;
  }
  .screenshots-tabs {
    max-width: 100%;
  }
}

/* Min width 1550px */
@media only screen and (min-width: 1550px) {
  .gradient-main-banner .container-fluid {
    padding-left: 100px;
    padding-right: 100px;
  }
  .gradient-main-banner-inner {
    padding-left: 100px;
    padding-right: 100px;
  }
  .gradient-main-banner-inner .banner-shape3 {
    right: 4%;
  }
  .banner-shape10 {
    right: 5%;
  }
}
/*# sourceMappingURL=home-six.css.map */
.center-content{/* UI Properties */
  background-image:linear-gradient( 160deg, rgb(255, 255, 255) 53%, #ff744833 100%);
border: 1px solid #fff3f0;  padding: 40px;
  border-radius: 25px; margin:0 0 20px;  min-height: 350px;
}
  .whyapp{ background: #F7F7FF;}
  .whyapp img{ }
 .whyapp .img-size { width:50px !important;}
 .center-content h2{ font-size: 18px;}
 .center-content h2 img { margin-bottom: 20px;}
 img.imgsize { width: 250px; margin-bottom: 40px; }
 .funfacts-box{ border-radius: 25px; }
 .m-t-2 { margin-top: 10px; }
 .pricing-area:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  background: #2C2C2C;
  height: 250px;
  width: 100%; }
  .pricing-area .container { position: relative; z-index: 1;}
  .full-width.section-title { width: 100%; max-width:100%; }
.p-t-4 { padding-top: 40px;}
.navbar-brand { width: 200px; }
.is-sticky .navbar-brand { width: 200px;}
.single-footer-widget .logo{ width: 200px;}
.about-content.logotxt h3 span{
  font-size: 36px;
  line-height: 1.4;
  margin-bottom: 15px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: 400;
}  .about-content.logotxt h3 em{ font-family: 'Josefin Sans', sans-serif;}
.section-title.price{max-width: 100%; margin-top: 40px;
  padding: 40px 0; border-radius: 15px;
  background: #f4ddff;}
.about-content.logotxt h3 em {font-style: normal;
  color: var(--mainColor);
  font-weight: 600;}
.awesome-feat-img .feature-link {
    display: none;
}.awesome-feat-img .feature-link.active {
  display: block;
  visibility: visible;
  opacity: 1;    -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;}
  @-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

body::-webkit-scrollbar {
  display: none;
}