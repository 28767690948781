.upperSection, .buttonSection {
  margin-top: 10%;
}

.confirmCodeTxt {
  margin-top: 25%;
}

.enterOtpSection {
  width: 50%;
  margin: 10% auto 0 auto;
}

.checkMessageTxtSection {
  margin: 5% 0;
}

.instructions,
.qr {
  margin: 50px;
}

.settings-desktop-text {
  font-size: 12px;
}

.hookzappPrimaryColor {
  background-color: #ff3400 !important;
}

.hookzappSecondaryColor {
  background-color: #439bff !important;
}

.hookzappTertiaryColor {
  background-color: #f6f6f6 !important;
}

.hookzappPrimaryTextColor {
  color: #ff3400 !important;
}

.hookzappSecondaryTextColor {
  color: #439bff !important;
}

.verifyBtn {
  border-radius: 50px !important;
  font-size: 15px !important;
  height: 50px !important;
  /* width: 60% !important; */
  margin-top: 10px !important;
  z-index: 0 !important;
  border: none !important;
}

.nextBtn{
  border-radius: 50px !important;
  font-size: 15px !important;
  height: 50px !important;
  width: 100% !important;
  margin-top: 10px !important;
  z-index: 0 !important;
  border: none !important;
}

.loginBtnStyle {
  border-radius: 50px !important;
  font-size: 15px !important;
}

.qrBtn{
  border-radius: 50px !important;
  font-size: 15px !important;
  width: 225px !important;
  height: 50px !important;
  border: none !important;
}

.linkStyle {
  cursor: pointer !important;
  text-decoration: underline !important;
}

.qrIcons {
  padding: 10px !important;
  border-radius: 20px !important;
}

.needHelpToGetStarted {
  font-size: 12px;
}

.otpInput {
  border: none !important;
  border-radius: 5px !important;
  margin-top: 20px !important;
}

.didNotGetCode{
  color:#009DFF;
  cursor: pointer !important;
}

.logoStyles {
  height: 1.8rem;
  width: auto;
  padding-bottom: 0.1rem;
}

@media (max-width: 768px) {
  .upperSection, .buttonSection, .enterOtpSection {
    margin-top: 33%;
  }

  .enterOtpSection {
    margin: 33% auto 0 auto;
  }
}